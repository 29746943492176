import { useModal } from "../../components/ModalProvider";
import { Carousel } from "react-responsive-carousel";
import { Slide } from "./Slide";
import { ShowboxContent } from "./Showbox";
import galleryImages from "../brochure/images.json";

const images: string[] = galleryImages
  .filter((image) => image.imageKey.toLowerCase().startsWith("b"))
  .map(
    (image) => `https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure/${image.imageKey}`
  );

export const OurVineyardModal = () => {
  const { closeModal } = useModal();
  return (
    <div className="modal showbox">
      <ShowboxContent>
        <button className="button button-active" onClick={closeModal}>
          Close gallery
        </button>
        <h1 className="header">OUR VINEYARD</h1>
        <p>
          Sticle Vineyard is home to over 10,000 vines, growing on 25 acres of beautiful land. The
          family owned vineyard was established in 2019 and will be limited to 10,000 bottles of
          each of its 3 Cuvée, so not to overwork its vines.
        </p>
      </ShowboxContent>
      <Carousel
        emulateTouch
        autoPlay
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        className="showbox-images select-none"
      >
        {images.map((image) => (
          <Slide>
            <img src={image} />
          </Slide>
        ))}
      </Carousel>
    </div>
  );
};
