import React from "react";

type GalleryImageCardProps = {
  imageUrl: string;
};

export const GalleryImageCard = (props: GalleryImageCardProps) => {
  return (
    <div
      className="image"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6)), url('${props.imageUrl}')`
      }}
    />
  );
};
