import { ComponentProps } from "react";
import styled from "styled-components";

export const ShowboxContent = styled((props: ComponentProps<"div">) => {
  return <div {...props} />;
})`
  flex: 1 0 35%;
  margin: 2rem 0;
  padding: 0 2rem;
`;
