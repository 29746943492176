import React from "react";
import { useModal } from "../../components/ModalProvider";
import { Slide } from "./Slide";
import { ShowboxContent } from "./Showbox";

export const BookEventModal = () => {
  const { closeModal } = useModal();
  return (
    <div className="modal showbox">
      <ShowboxContent>
        <button className="button button-active" onClick={closeModal}>
          Close gallery
        </button>
        <h1 className="header">BOOK AN EVENT</h1>
        <div className="flex flex-col gap-2">
          <p className="mb-4">
            Send an inquiry email to{" "}
            <a href="mailto:enquiry@sticlevineyard.co.uk" className="font-semibold">
              enquiry@sticlevineyard.co.uk
            </a>
          </p>
          <p>
            We are happy to help you design your special event. Please use our enquiry email to
            contact us. Please include your name, telephone number and the event you wish to host
            with the date of the event.
          </p>
          <p>
            <span className="block font-semibold">Celebrating Life's Special Moments</span>
            When the time calls for Celebrating Life's Special Moments, we are here to help you
            design your event to create the most memorable time to be shared with family and
            friends.
          </p>
          <p className="font-semibold">Celebrate the Holidays</p>
          <p>
            <span className="block font-semibold">
              Book your Business Meetings and Video Conferencing{" "}
            </span>
            Sticle Vineyard benefits from having its own dedicated ultra-fast internet.
          </p>
          <p>
            <span className="block font-semibold">Art Gallery Exhibitions</span>
            We offer spaces in the Vineyard or in our large hall inside our Winery Building. We help
            you design the event to your specifications.
          </p>
        </div>
      </ShowboxContent>
      <div className="w-72 select-none flex-shrink-0">
        <Slide>
          <img
            src="https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure/airbnb_sticle.jpg"
            className="w-full"
          />
        </Slide>
      </div>
    </div>
  );
};
