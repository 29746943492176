import { useModal } from "../../components/ModalProvider";
import { Carousel } from "react-responsive-carousel";
import { Slide } from "./Slide";
import { ShowboxContent } from "./Showbox";
import galleryImages from "../brochure/images.json";

const images: string[] = galleryImages
  .filter((image) => image.imageKey.toLowerCase().startsWith("g"))
  .map(
    (image) => `https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure/${image.imageKey}`
  );

export const OurGardensModal = () => {
  const { closeModal } = useModal();
  return (
    <div className="modal showbox">
      <ShowboxContent>
        <button className="button button-active" onClick={closeModal}>
          Close gallery
        </button>
        <h1 className="header">OUR WILD GARDENS</h1>
        <p>
          Nestled 800 ft above sea level in the undulating Welsh landscape, Sticle enjoys
          spectacular views, blending Heaven and Earth. For generations, its undisturbed lands have
          benefited from a rich Celtic history.
        </p>
      </ShowboxContent>
      <Carousel
        emulateTouch
        autoPlay
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        className="showbox-images select-none"
      >
        {images.map((image) => (
          <Slide>
            <img src={image} className="w-full" />
          </Slide>
        ))}
      </Carousel>
    </div>
  );
};
