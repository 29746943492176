import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LandingPage } from "./LandingPage";
import { BrochurePage } from "./BrochurePage";
import { ModalProvider } from "./components/ModalProvider";

function App() {
  return (
    <BrowserRouter>
      <ModalProvider>
        <Routes>
          <Route path="brochure" element={<BrochurePage />} />
          <Route index element={<LandingPage />} />
        </Routes>
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
