import React from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import galleryImages from "./features/brochure/images.json";

export const BrochurePage = () => {
  const imageBuckets = galleryImages.reduce((acc: any, image) => {
    const category = image.imageKey[0];
    const bucket = acc[category] || [];

    bucket.push(image);
    acc[category] = bucket;
    return acc;
  }, {});

  return (
    <>
      <section className="flex navbar justify-center">
        <div className="sticle-logo"></div>
      </section>

      <section id="discover-sticle" className="content-section">
        <div className="content-section-title">
          <h3 className="subtitle">OFFERS IN EXCESS OF £1,500,000</h3>
          <h1 className="header">STICLE VINEYARD</h1>
          <span className="w-full max-w-lg">
            Set within a 34-acre wine estate in the stunning Gower Peninsula of Southwest Wales,
            this property offers majestic views from every angle and complete privacy. This
            revenue-generating estate features not only a vineyard but also a guest house, event
            hosting, and WISP provision. Enjoy the tranquility of a secluded estate while still
            being within easy reach of Wales's best beaches, activities, supermarkets, and schools,
            and with convenient access to London via the M4 or direct train to Paddington station.
          </span>
          <div className="mt-8">
            <a
              href="https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure/Sticle+Vineyard+Brochure.pdf"
              target="_blank"
              rel="noreferrer"
              className="button button-active"
            >
              Download brochure
            </a>
          </div>
          <div className="sticle-video rounded-xl overflow-hidden">
            <video width="100%" height="100%" controls>
              <source
                src="https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure/Sticle+Vineyard+2.mov"
                type="video/mp4"
              />
              Your browser does not support video.
            </video>
          </div>
        </div>
        <div className="content-section-background-image"></div>
      </section>

      <section id="images" className="content-section">
        {Object.keys(imageBuckets).map((bucketKey: any) => {
          const bucket = imageBuckets[bucketKey];
          return (
            <div className="w-screen mt-24">
              {/*<div className="flex flex-col gap-2 text-center my-8">*/}
              {/*  <p className="text-3xl font-extrabold tracking-[-0.04em] text-black sm:text-5xl sm:leading-[3.5rem]">*/}
              {/*    {bucketNames[bucketKey]?.title || `Section '${bucketKey}' needs a title`}*/}
              {/*  </p>*/}
              {/*  <p className="text-lg font-semibold text-slate-600">*/}
              {/*    {bucketNames[bucketKey]?.title || `Section '${bucketKey}' needs a description`}*/}
              {/*  </p>*/}
              {/*</div>*/}
              <Gallery>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(260px, 1fr))",
                    gridTemplateRows: "repeat(auto-fit, 260px)",
                    gridAutoFlow: "dense",
                    gridGap: "0.3rem"
                  }}
                >
                  {bucket.map((image: any) => {
                    const s3BucketUrl =
                      "https://sticlevineyard.s3.eu-west-2.amazonaws.com/images/brochure";
                    return (
                      <Item
                        original={`${s3BucketUrl}/${image.imageKey}`}
                        thumbnail={`${s3BucketUrl}/${image.thumbnailImageKey}`}
                        width={image.width}
                        height={image.height}
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            onClick={open}
                            src={`${s3BucketUrl}/${image.thumbnailImageKey}`}
                            className="pointer"
                          />
                        )}
                      </Item>
                    );
                  })}
                </div>
              </Gallery>
            </div>
          );
        })}
      </section>
    </>
  );
};
