import styled from "styled-components";

export const Slide = styled.div`
  max-height: 600px;
  height: 100%;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
