import React, { createContext, Fragment, ReactNode, useContext, useState } from "react";
import { DialogBackdrop, Transition } from "@headlessui/react";
import { Portal } from "@reach/portal";
import styled from "styled-components";

const ModalWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const Dialog = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: ${({ zIndex = 0 }) => 20 + zIndex};
`;

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextProperties = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextProperties>({
  openModal: () => {},
  closeModal: () => {}
});

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

type State = {
  modals: ReactNode[];
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [state, setState] = useState<State>({
    modals: []
  });

  const openModal = (modal: ReactNode): void => {
    setState((prevState) => {
      return {
        modals: [...prevState.modals, modal]
      };
    });
  };

  const closeModal = (): void => {
    setState((prevState) => {
      return {
        modals: prevState.modals.slice(0, prevState.modals.length - 1)
      };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal
      }}
    >
      {children}
      {state.modals.map((Modal, index) => {
        return (
          <Portal key={`portal_${index}`}>
            <Transition.Root appear show as={Fragment}>
              <Dialog zIndex={index} className="modal-wrapper visible">
                <div className="fixed inset-0 bg-black/30" onClick={closeModal} />
                {Modal}
              </Dialog>
            </Transition.Root>
          </Portal>
        );
      })}
    </ModalContext.Provider>
  );
};
